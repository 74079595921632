@use "sass:map";
@use "../../vars" as vars;

.home {
  font-family: map.get(vars.$fonts, default);
  background: linear-gradient(
    to bottom,
    map.get(vars.$colors, skyBlue),
    map.get(vars.$colors, lavender)
  );
  color: map.get(vars.$colors, textPrimary);
  flex-grow: 1;

  .home-header {
    text-align: center;
    margin: map.get(vars.$rSpacing, md);

    h1 {
      font-size: map.get(vars.$fontSizes, xxxl);
      color: map.get(vars.$colors, primary);
      text-shadow: map.get(vars.$shadows, small);
    }

    p {
      font-size: map.get(vars.$fontSizes, xxl);
      color: map.get(vars.$colors, textprimaryDark);
    }
  }

  .home-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: map.get(vars.$rSpacing, md);

    .features {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: map.get(vars.$rSpacing, md);
      width: 100%;
      max-width: 1200px;
      align-items: start;

      .feature-card {
        display: block;
        background: map.get(vars.$colors, white);
        border: 1px solid map.get(vars.$colors, border);
        border-radius: map.get(vars.$radius, medium);
        box-shadow: map.get(vars.$shadows, small);
        padding: map.get(vars.$rSpacing, sm);
        margin: map.get(vars.$rSpacing, sm);
        text-align: center;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        color: map.get(vars.$colors, textPrimary);
        text-decoration: none;

        &:hover {
          transform: translateY(-5px);
          box-shadow: map.get(vars.$shadows, medium);
          color: map.get(vars.$colors, textPrimary);
        }

        h2 {
          font-size: map.get(vars.$fontSizes, xxl);
          color: map.get(vars.$colors, primary);
          margin-bottom: map.get(vars.$rSpacing, sm);
        }

        p {
          font-size: map.get(vars.$fontSizes, lg);
          color: map.get(vars.$colors, textprimaryDark);
        }
      }
    }
  }
}
