@use "sass:map";
@use "../../vars" as vars;

.signup-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: map.get(vars.$rSpacing, lg);

  .signup-form {
    display: flex;
    flex-direction: column;
    gap: map.get(vars.$rSpacing, md);
    width: 100%;
    max-width: 400px;
    padding: map.get(vars.$rSpacing, md);
    background-color: map.get(vars.$colors, white);
    border-radius: map.get(vars.$rSpacing, xs);
    box-shadow: map.get(vars.$shadows, small);

    h2 {
      font-size: map.get(vars.$fontSizes, xl);
    }

    input,
    button {
      padding: map.get(vars.$rSpacing, sm);
      margin: calc(map.get(vars.$rSpacing, sm) / 2) map.get(vars.$rSpacing, sm);
      font-size: map.get(vars.$fontSizes, md);
      border-radius: map.get(vars.$rSpacing, s);
      background-color: map.get(vars.$colors, snow);
    }

    .initial-input,
    .error-response {
      margin-top: map.get(vars.$rSpacing, sm);
      font-size: map.get(vars.$fontSizes, md);
    }

    button {
      background-color: map.get(vars.$colors, primary);
      color: map.get(vars.$colors, snow);
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: map.get(vars.$colors, primaryDark);
      }
    }

    @media (min-width: map.get(vars.$breakpoints, tablet)) {
      max-width: 500px;
    }

    @media (min-width: map.get(vars.$breakpoints, laptop)) {
      max-width: 600px;
    }
  }
}
