@use "sass:map";
@use "../../../../../vars" as vars;

.review-slide-container {
  width: clamp(500px, 50cqmax, 75cqmin);
  height: calc(clamp(500px, 50cqmax, 75cqmin) * 9 / 16);
  background-color: map.get(vars.$colors, primary);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-size: cover;
  background-position: center;

  .review-overlay {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  .review-title {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 20cqh;
    left: 75%;
    transform: translateX(-50%) translateY(-50%);

    > li {
      padding: map.get(vars.$rSpacing, xs);
      background-color: rgba(map.get(vars.$colors, primary), 0.75);
      font-size: map.get(vars.$fontSizes, xl);
      width: 30cqmin;
      &:nth-child(1) {
        margin-left: 0;
        border-radius: map.get(vars.$radius, large) map.get(vars.$radius, large)
          0 map.get(vars.$radius, large);
      }
      &:nth-child(2) {
        margin-left: 5cqb;
        border-radius: 0 map.get(vars.$radius, large)
          map.get(vars.$radius, large) map.get(vars.$radius, large);
      }
    }
  }

  &.slide-fullscreen {
    > .review-overlay > .review-title > li {
      width: 70cqmin;
      top: 20cqh;
      font-size: map.get(vars.$fontSizes, mxl);
    }
  }
}
