@use "sass:map";
@use "../../../../../vars" as vars;

.visual-review-consonant {
  width: clamp(500px, 50cqmax, 75cqmin);
  height: calc(clamp(500px, 50cqmax, 75cqmin) * 9 / 16);
  background-color: map.get(vars.$colors, primary);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .bounty-bar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 15%;
    background-color: map.get(vars.$colors, primaryDark);

    > h1 {
      font-size: map.get(vars.$fontSizes, xxl);
      padding: map.get(vars.$rSpacing, sm);
    }
  }

  .block-wall {
    align-items: center;
    margin-left: auto;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 65%;
    background-color: map.get(vars.$colors, primaryDark);
    z-index: 3;

    transition: transform 0.5s linear;
    transform: translateY(0);

    &.block-wall-down {
      transform: translateY(100%);
    }
  }

  .grapheme-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-grow: 1;
    width: 100%;
  }

  .grapheme-tile {
    font-size: map.get(vars.$fontSizes, xxl);
    padding: map.get(vars.$rSpacing, sm);
    margin: map.get(vars.$rSpacing, sm);
    height: 15%;
    aspect-ratio: 1/1;
    background: map.get(vars.$colors, snow);
    border-radius: map.get(vars.$radius, small);
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(map.get(vars.$colors, charcoal), 0.2);
    display: flex;
    align-items: center;
    justify-content: center;

    &.gt-fullscreen {
      font-size: map.get(vars.$fontSizes, xxxl);
    }
  }

  .background-container {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 65%;
    bottom: 0;

    .sound-wall {
      align-items: center;
      flex-grow: 1;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-size: 100% 100%;
      z-index: 1;
    }
  }

  .photo-container {
    position: absolute;
    bottom: 0%;
    z-index: 4;
    width: 100%;
    height: 65%;

    opacity: 0;
    transition: opacity 0.5s linear, transform 0.5s linear;
    transform: translateY(0);

    &.photo-container-show {
      opacity: 1;
    }

    &.photo-container-down {
      transform: translateY(100%);
    }

    .photo-tile {
      position: absolute;
      max-height: 98%;
      top: 1%;
      left: 50%;
      transform: translateX(-49%);
    }

    &.layout-2 {
      .photo-1 {
        left: 10%;
        top: 1%;
        height: 98%;
        transform: translateX(0);
      }
      .photo-2 {
        top: 1%;
        left: 90%;
        height: 98%;
        transform: translateX(-98%);
      }
    }

    &.layout-3 {
      .photo-1 {
        left: 0.5%;
        top: 5.5%;
        height: 89%;
        transform: translateX(0);
      }
      .photo-2 {
        top: 5.5%;
        left: 49.8%;
        height: 89%;
        transform: translateX(-49%);
      }
      .photo-3 {
        top: 5.5%;
        left: 99%;
        height: 89%;
        transform: translateX(-98%);
      }
    }

    &.layout-4 {
      .photo-1 {
        left: 1%;
        top: 10%;
        height: 80%;
        transform: translateX(0);
      }
      .photo-2 {
        top: 10%;
        left: 37.5%;
        height: 80%;
        transform: translateX(-49%);
      }
      .photo-3 {
        top: 10%;
        left: 62.5%;
        height: 80%;
        transform: translateX(-49%);
      }
      .photo-4 {
        top: 10%;
        left: 99%;
        height: 80%;
        transform: translateX(-98%);
      }
    }
  }
}
