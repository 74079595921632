@use "sass:map";
@use "../../../../../vars" as vars;

.audio-review-consonant {
  width: clamp(500px, 50cqmax, 75cqmin);
  height: calc(clamp(500px, 50cqmax, 75cqmin) * 9 / 16);
  background-color: map.get(vars.$colors, primary);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .bounty-bar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 15%;
    background-color: map.get(vars.$colors, primaryDark);

    > h1 {
      font-size: map.get(vars.$fontSizes, xxl);
      padding: map.get(vars.$rSpacing, sm);
    }
  }

  .block-wall {
    align-items: center;
    margin-left: auto;
    position: absolute;
    right: 0;
    width: 70%;
    height: 100%;
    background-color: map.get(vars.$colors, primaryDark);
    z-index: 3;

    transition: transform 0.5s linear;
    transform: translateX(0);

    &.block-wall-right {
      transform: translateX(100%);
    }
  }

  .arc-slide-content {
    flex-grow: 1;
    display: flex;
    flex-direction: row;

    .grapheme-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30%;
      opacity: 0;
      transition: opacity 0.5s linear;

      &.single-column {
        flex-direction: column;
      }

      &.two-columns {
        flex-wrap: wrap;
        align-content: center;
      }

      &.gc-fade-in {
        opacity: 1;
      }

      &.gc-no-fade {
        transition: opacity 0s linear;
      }
    }

    .grapheme-tile {
      font-size: map.get(vars.$fontSizes, xxl);
      padding: map.get(vars.$rSpacing, sm);
      margin: map.get(vars.$rSpacing, sm);
      width: 30%;
      aspect-ratio: 1/1;
      background: map.get(vars.$colors, snow);
      border-radius: map.get(vars.$radius, small);
      font-weight: bold;
      box-shadow: 0 2px 5px rgba(map.get(vars.$colors, charcoal), 0.2);
      display: flex;
      align-items: center;
      justify-content: center;

      &.gt-fullscreen {
        font-size: map.get(vars.$fontSizes, xxxl);
      }
    }
  }

  .background-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
    right: 0;

    .sound-wall {
      align-items: center;
      flex-grow: 1;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-size: 100% 100%;
      z-index: 1;
    }
  }

  .photo-container {
    position: absolute;
    left: 30%;
    z-index: 4;
    width: 70%;
    height: 100%;

    transition: transform 0.5s linear;
    transform: translateX(0);

    &.photo-container-right {
      transform: translateX(100%);
    }

    .photo-tile {
      position: absolute;
      max-height: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

    &.layout-2 {
      .photo-1 {
        transform: translateX(0%);
        left: 1%;
        top: 1%;
        height: 60%;
      }
      .photo-2 {
        transform: translateX(-100%);
        top: 39%;
        left: 99%;
        height: 60%;
      }
    }

    &.layout-3 {
      .photo-1 {
        transform: translateX(0%);
        left: 10.25%;
        top: 1%;
        height: 49%;
      }
      .photo-2 {
        transform: translateX(-100%);
        top: 1%;
        left: 89.75%;
        height: 49%;
      }
      .photo-3 {
        transform: translateX(-50%);
        top: 51%;
        left: 50%;
        height: 48%;
      }
    }

    &.layout-4 {
      .photo-1 {
        transform: translateX(0%);
        left: 7%;
        top: 1%;
        height: 48%;
      }
      .photo-2 {
        transform: translateX(-100%);
        top: 1%;
        left: 93%;
        height: 48%;
      }
      .photo-3 {
        transform: translateX(0%);
        top: 51%;
        left: 7%;
        height: 48%;
      }
      .photo-4 {
        transform: translateX(-100%);
        top: 51%;
        left: 93%;
        height: 48%;
      }
    }

    &.layout-5 {
      .photo-1 {
        transform: translateX(0%);
        left: 6%;
        top: 1%;
        height: 50%;
      }
      .photo-2 {
        transform: translateX(-100%);
        top: 1%;
        left: 94%;
        height: 50%;
      }
      .photo-3 {
        transform: translateX(0%);
        top: 56.5%;
        left: 2%;
        height: 38%;
      }
      .photo-4 {
        transform: translateX(-50%);
        top: 56.5%;
        left: 50%;
        height: 38%;
      }
      .photo-5 {
        transform: translateX(-100%);
        top: 56.5%;
        left: 98%;
        height: 38%;
      }
    }

    &.layout-6 {
      .photo-1 {
        transform: translateX(0%);
        top: 8%;
        left: 2%;
        height: 38%;
      }
      .photo-2 {
        transform: translateX(-50%);
        top: 8%;
        left: 50%;
        height: 38%;
      }
      .photo-3 {
        transform: translateX(-100%);
        top: 8%;
        left: 98%;
        height: 38%;
      }
      .photo-4 {
        transform: translateX(0%);
        top: 50%;
        left: 2%;
        height: 38%;
      }
      .photo-5 {
        transform: translateX(-50%);
        top: 50%;
        left: 50%;
        height: 38%;
      }
      .photo-6 {
        transform: translateX(-100%);
        top: 50%;
        left: 98%;
        height: 38%;
      }
    }
  }
}
