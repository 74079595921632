@use "sass:map";
@use "../../../../../vars" as vars;

.blend-slide-container {
  width: clamp(500px, 50cqmax, 75cqmin);
  height: calc(clamp(500px, 50cqmax, 75cqmin) * 9 / 16);
  background-color: map.get(vars.$colors, primary);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-size: cover;
  background-position: center;

  .blend-overlay {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  .blend-title {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translateX(30%) translateY(-50%);

    > li {
      padding: map.get(vars.$rSpacing, xs);
      background-color: rgba(map.get(vars.$colors, primary), 0.75);
      font-size: map.get(vars.$fontSizes, xl);
      width: 30cqmin;
      border-radius: map.get(vars.$radius, large);
    }
  }

  &.slide-fullscreen {
    > .blend-overlay > .blend-title > li {
      width: 80cqmin;
      top: 50%;
      font-size: map.get(vars.$fontSizes, mxl);
    }
  }
}
