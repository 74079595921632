@use "sass:map";
@use "../../../vars" as vars;

.consonant-sounds-wrapper .consonant-sounds-grid .cell {
  &.cell-tile {
    display: flex;
    justify-content: flex-end;
    overflow: visible;

    img {
      position: absolute;
      left: 0%;
      width: 40%;
      object-fit: contain;
      z-index: 1;

      &.not-face {
        z-index: 2;
      }

      &.one-voiced-sound {
        width: 30%;
        top: 63%;
        left: -15%;
      }

      &.voiced-sound {
        width: 30%;
        top: 25%;
        left: -15%;
      }

      &.item-photo {
        position: absolute;
        top: -75%;
        left: 50%;
        width: 150%;
        height: 150%;
        object-fit: contain;
        z-index: 2;

        &:last-of-type {
          top: 50%;
          left: 75%;
        }
      }
    }

    > .graphemes-inner-cell {
      display: flex;
      flex-direction: column;
      width: 50%;

      > h1 {
        width: 100%;
        font-size: map.get(vars.$fontSizes, xs);

        &.fullscreen {
          font-size: map.get(vars.$fontSizes, xl);
        }
      }
    }
  }
}
