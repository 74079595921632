@use "sass:map";
@use "../../vars" as vars;

.not-found {
  background: url("../../Utils/Assets/Backgrounds/404.webp") no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: map.get(vars.$rSpacing, lg);
  flex-grow: 1;

  .content-wrapper {
    background-color: rgba(255, 255, 255, 0.8);
    padding: map.get(vars.$rSpacing, lg);
    border-radius: map.get(vars.$rSpacing, sm);
    text-align: center;
  }

  h1 {
    font-size: map.get(vars.$fontSizes, xxxl);
    font-weight: map.get(vars.$fontWeights, bold);
    margin-bottom: map.get(vars.$rSpacing, md);
  }

  p {
    font-size: map.get(vars.$fontSizes, xl);
    margin-bottom: map.get(vars.$rSpacing, sm);
  }

  .icon {
    color: map.get(vars.$colors, errorRed);
    font-size: map.get(vars.$fontSizes, xxxl);
    margin-bottom: map.get(vars.$rSpacing, md);
  }
}