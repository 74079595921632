.lesson-container {
  min-height: 75cqh;
}

.lesson-container > * {
  &.slide-fullscreen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100vw;
    max-height: 100vh;
    width: 100vw;
    height: calc(100vw * 9 / 16);
  }
}
