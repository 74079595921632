@use "sass:map";
@use "../../../../../vars" as vars;

.blending-routine-container {
  width: clamp(500px, 50cqmax, 75cqmin);
  height: calc(clamp(500px, 50cqmax, 75cqmin) * 9 / 16);
  background-color: map.get(vars.$colors, primary);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: center;

  .grapheme-row {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: map.get(vars.$rSpacing, sm);
    flex-grow: 1;
    transition: flex-grow 1s ease;
  }

  .grapheme-tile {
    background: white;
    padding: map.get(vars.$rSpacing, sm);
    font-size: map.get(vars.$fontSizes, xl);
    border-radius: map.get(vars.$radius, medium);
    transition: transform 0.3s, border-radius 1s;
    position: relative;
  }

  .grapheme-tile.teeter {
    animation: teeter 0.25s linear infinite alternate;
  }

  .grapheme-tile.grow {
    animation: grow 1s ease forwards;
  }

  .grapheme-tile.pulse {
    animation: pulse 1s ease;
  }

  .grapheme-tile.vowel {
    background-color: map.get(vars.$colors, vowel);
  }

  .grapheme-tile.schwa {
    background-color: map.get(vars.$colors, schwa);
  }

  .grapheme-tile.consonant {
    background-color: map.get(vars.$colors, consonant);
  }

  .grapheme-tile.le-consonant {
    background: linear-gradient(
      90deg,
      map.get(vars.$colors, consonant),
      map.get(vars.$colors, specialCombination)
    );

    &.grow {
      border-radius: map.get(vars.$radius, medium) 0 0
        map.get(vars.$radius, medium);
    }
  }

  .grapheme-tile.le-special {
    background-color: map.get(vars.$colors, specialCombination);

    &.grow {
      border-radius: 0 map.get(vars.$radius, medium) map.get(vars.$radius, medium)
        0;
    }
  }

  .grapheme-tile.no-border.grow {
    border-radius: 0 0 0 0;
  }

  .grapheme-tile.gray {
    background-color: map.get(vars.$colors, defaultTile);
  }

  .arrow {
    position: absolute;
    bottom: 30%;
    height: 6px;
    border: 4px solid map.get(vars.$colors, accent);
    border-radius: 4px;
    transition: all 0.3s ease;

    &::after {
      content: "";
      position: absolute;
      right: -5px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      width: 10px;
      height: 10px;
      border-top: 6px solid map.get(vars.$colors, accent);
      border-right: 6px solid map.get(vars.$colors, accent);
    }
  }

  .final-reveal {
    text-align: center;
    display: flex;
    height: 0;
    width: 100%;
    transition: flex-grow 1s ease;

    .grapheme-row.top {
      margin-bottom: 1rem;
    }

    .photo-title {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .photo-image {
      height: 0%;
      object-fit: contain;
      aspect-ratio: 1 / 1;
    }

    &.show {
      flex-grow: 6;
      align-items: center;
      justify-content: center;

      > .photo-image {
        height: 90%;
        border: 6px solid map.get(vars.$colors, accent);
      }
    }
  }

  //   &.pulse {
  //     animation: pulse 1s ease-in-out infinite;
  //   }

  //   &.spin {
  //     animation: spin 1s linear infinite;
  //   }

  //   &.cross-out {
  //     text-decoration: line-through;
  //     color: gray;
  //   }

  &.slide-fullscreen > .grapheme-row {
    gap: map.get(vars.$rSpacing, md);

    .grapheme-tile {
      padding: map.get(vars.$rSpacing, sm) map.get(vars.$rSpacing, md);
      font-size: map.get(vars.$fontSizes, mxl);
    }
    .arrow {
      bottom: 35%;
    }
  }
}

@keyframes teeter {
  from {
    transform: rotate(3deg);
  }
  to {
    transform: rotate(-3deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.33);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes grow {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.33);
  }
}
