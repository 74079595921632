@use "sass:map";
@use "../../vars" as vars;

.audio-test {
  font-family: map.get(vars.$fonts, default);
  background: linear-gradient(
    to bottom,
    map.get(vars.$colors, skyBlue),
    map.get(vars.$colors, lavender)
  );
  color: map.get(vars.$colors, textPrimary);
  flex-grow: 1;
  padding: map.get(vars.$rSpacing, md);

  h1 {
    text-align: center;
    font-size: map.get(vars.$fontSizes, xxxl);
    color: map.get(vars.$colors, primary);
    text-shadow: map.get(vars.$shadows, small);
    margin-bottom: map.get(vars.$rSpacing, md);
  }

  .recorder {
    display: flex;
    justify-content: center;
    margin-bottom: map.get(vars.$rSpacing, md);

    button {
      background-color: map.get(vars.$colors, primary);
      color: map.get(vars.$colors, white);
      border: none;
      border-radius: map.get(vars.$radius, medium);
      cursor: pointer;
      padding: map.get(vars.$rSpacing, sm);
      font-size: map.get(vars.$fontSizes, xxxl);
      transition: background-color 0.3s ease;

      &:hover {
        background-color: map.get(vars.$colors, primaryDark);
      }
    }
  }

  audio {
    display: block;
    margin: 0 auto;
    margin-bottom: map.get(vars.$rSpacing, md);
  }

  .error {
    color: map.get(vars.$colors, error);
    text-align: center;
    font-size: map.get(vars.$fontSizes, xl);
    margin-bottom: map.get(vars.$rSpacing, sm);
  }

  .transcription {
    color: map.get(vars.$colors, textprimaryDark);
    text-align: center;
    font-size: map.get(vars.$fontSizes, xxxl);
    margin-top: map.get(vars.$rSpacing, md);
  }
}
