@use "sass:map";
@use "../../vars" as vars;

.major-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: map.get(vars.$colors, primary);
  color: map.get(vars.$colors, snow);
  padding: map.get(vars.$rSpacing, xs);

  .brand {
    font-size: map.get(vars.$fontSizes, xl);
    font-weight: map.get(vars.$fontWeights, bold);
    display: flex;
    align-items: center;

    .app-icon {
      width: calc(map.get(vars.$fontSizes, xl) * 2);
      border-radius: map.get(vars.$rSpacing, sm);
      margin-right: map.get(vars.$rSpacing, sm);
    }
    cursor: pointer;
    h1 {
      font-size: map.get(vars.$fontSizes, xl);
      color: map.get(vars.$colors, snow);
      text-decoration: none;
    }
  }

  .menu {
    display: flex;
    list-style: none;
    li,
    button {
      display: flex;
      align-items: center;
      padding: map.get(vars.$rSpacing, xs);

      font-size: map.get(vars.$fontSizes, lg);
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  button {
    background-color: transparent;
    font-size: map.get(vars.$fontSizes, lg);
    margin: 0;
  }

  .menu-icon {
    display: none;
    font-size: calc(map.get(vars.$fontSizes, xl) * 2);
    cursor: pointer;
  }

  .close-icon {
    display: block;
    font-size: calc(map.get(vars.$fontSizes, xl) * 2);
    cursor: pointer;
    margin-bottom: map.get(vars.$rSpacing, sm);
  }

  .blur {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: map.get(vars.$zIndex, modal);
    &.show {
      display: block;
      animation: blurIn 0.5s ease-in-out;
    }
  }

  .sidebar {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background-color: map.get(vars.$colors, primary);
    z-index: map.get(vars.$zIndex, modal);
    padding: map.get(vars.$rSpacing, md);
    ul {
      list-style: none;
      li,
      button {
        margin-bottom: map.get(vars.$rSpacing, md);
        font-size: map.get(vars.$fontSizes, xxl);
        text-wrap: nowrap;
        width: 100%;
        text-align: left;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &.show {
      display: block;
      animation: slideIn 0.5s ease-in-out;
    }
  }
}

@keyframes slideIn {
  from {
    right: -250px;
  }
  to {
    right: 0;
  }
}

@keyframes blurIn {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}

@media (min-width: map.get(vars.$breakpoints, phone)) {
  .major-navbar {
    .menu {
      display: none;
    }
    .search-container {
      display: none;
    }
    .menu-icon {
      display: block;
    }
  }
}

// @media (min-width: map-get(vars.$breakpoints, tablet)) {
// ^This is to break the navbar up better for smaller screens
@media (min-width: 1140px) {
  .major-navbar {
    .menu {
      display: flex;
    }
    .search-container {
      display: none;
    }
    .search-icon {
      display: block;
    }
    .menu-icon {
      display: none;
    }
  }
}
