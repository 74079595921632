@use "sass:map";
@use "../../../../../vars" as vars;

.guided-phonics-lesson-container {
  width: clamp(500px, 50cqmax, 75cqmin);
  height: calc(clamp(500px, 50cqmax, 75cqmin) * 9 / 16);
  background-color: map.get(vars.$colors, primary);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .guided-phonics-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: 1;
  }

  .guided-phonics-overlay {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  .guided-phonics-title {
    font-size: map.get(vars.$fontSizes, xl);
    max-width: 75%;
    text-align: center;
    background-color: rgba(map.get(vars.$colors, primary), 0.75);
    padding: map.get(vars.$rSpacing, sm);
    border-radius: map.get(vars.$radius, large);
    color: map.get(vars.$colors, white);
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    &.gpt-fullscreen {
      font-size: map.get(vars.$fontSizes, xxxl);
    }
  }

  .guided-phonics-grade-label {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(map.get(vars.$colors, primary), 0.75);
    color: map.get(vars.$colors, white);
    border-radius: 0 50% 0 0;
    padding: map.get(vars.$rSpacing, md);
    font-size: map.get(vars.$fontSizes, md);
    text-align: center;
  }
}
