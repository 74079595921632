@use "sass:map";
@use "../../vars" as vars;

.phoneme-analysis {
  font-family: map.get(vars.$fonts, default);
  background: linear-gradient(
    to bottom,
    map.get(vars.$colors, skyBlue),
    map.get(vars.$colors, lavender)
  );
  color: map.get(vars.$colors, textPrimary);
  flex-grow: 1;
  padding: map.get(vars.$rSpacing, md);

  h1 {
    text-align: center;
    font-size: map.get(vars.$fontSizes, xxxl);
    color: map.get(vars.$colors, primary);
    text-shadow: map.get(vars.$shadows, small);
    margin-bottom: map.get(vars.$rSpacing, md);
  }

  input {
    display: block;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: map.get(vars.$rSpacing, sm);
    border: 1px solid map.get(vars.$colors, border);
    border-radius: map.get(vars.$radius, medium);
    margin-bottom: map.get(vars.$rSpacing, md);
    font-size: map.get(vars.$fontSizes, xl);
  }

  button {
    display: block;
    margin: 0 auto;
    background-color: map.get(vars.$colors, primary);
    color: map.get(vars.$colors, white);
    border: none;
    border-radius: map.get(vars.$radius, medium);
    cursor: pointer;
    padding: map.get(vars.$rSpacing, sm);
    margin-bottom: map.get(vars.$rSpacing, md);
    font-size: map.get(vars.$fontSizes, xxxl);
    transition: background-color 0.3s ease;

    &:hover {
      background-color: map.get(vars.$colors, primaryDark);
    }
  }

  .error {
    color: map.get(vars.$colors, error);
    text-align: center;
    font-size: map.get(vars.$fontSizes, xl);
    margin-bottom: map.get(vars.$rSpacing, sm);
  }

  .phon-analysis-grapheme {
    display: flex;
    flex-wrap: wrap;
    gap: map.get(vars.$rSpacing, sm);
    justify-content: center;

    .phon-tile {
      background-color: map.get(vars.$colors, white);
      color: map.get(vars.$colors, textPrimary);
      border: 1px solid map.get(vars.$colors, border);
      border-radius: map.get(vars.$radius, small);
      padding: map.get(vars.$rSpacing, sm);
      font-size: map.get(vars.$fontSizes, xxxl);
      box-shadow: map.get(vars.$shadows, small);
      text-align: center;
      min-width: 50px;
    }
  }
}
