@use "sass:map";
@use "../../vars" as vars;

.footer {
  background-color: map.get(vars.$colors, primary);
  color: map.get(vars.$colors, snow);
  padding: map.get(vars.$rSpacing, xs);

  .footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .footer-section {
      flex: 1;
      margin: map.get(vars.$rSpacing, sm);

      h2 {
        font-size: map.get(vars.$fontSizes, xxl);
        margin-bottom: map.get(vars.$rSpacing, md);
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: map.get(vars.$rSpacing, sm);

          span,
          button {
            color: map.get(vars.$colors, snow);
            text-decoration: none;
            background-color: transparent;
            font-size: map.get(vars.$fontSizes, xl);

            &:hover {
              text-decoration: underline;
              cursor: pointer;
            }
          }

          span {
            background-color: transparent;

            p {
              display: flex;
              align-items: center;
            }
          }
        }
      }

      p {
        margin: 0;
        font-size: map.get(vars.$fontSizes, xl);
      }

      .social-icons {
        display: flex;

        a {
          color: map.get(vars.$colors, snow);
          font-size: map.get(vars.$fontSizes, xxl);
          margin-right: map.get(vars.$rSpacing, sm);

          &:hover {
            color: map.get(vars.$colors, primaryDark);
          }
        }
      }
    }

    .contact {
      > span {
        display: flex;
        font-size: map.get(vars.$fontSizes, xxl);
        margin-bottom: map.get(vars.$rSpacing, sm);

        > svg {
          margin-right: map.get(vars.$rSpacing, sm);
        }
      }
    }
  }

  .footer-bottom {
    text-align: center;
    margin-top: map.get(vars.$rSpacing, sm);
    font-size: map.get(vars.$fontSizes, xl);
  }
}
