@use "sass:map";
@use "../../../../../vars" as vars;

.blending-review-overview-container {
  width: clamp(500px, 50cqmax, 75cqmin);
  height: calc(clamp(500px, 50cqmax, 75cqmin) * 9 / 16);
  background-color: map.get(vars.$colors, primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: map.get(vars.$rSpacing, sm);
  overflow-y: scroll;
  overflow-x: hidden;
  background-size: cover;
  background-position: center;

  &.slide-fullscreen {
    padding: map.get(vars.$rSpacing, md);
  }

  .blending-title {
    font-size: map.get(vars.$fontSizes, xl);
    font-weight: bold;
    margin-bottom: map.get(vars.$rSpacing, sm);
  }

  .blending-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
    max-width: 80%;
    gap: map.get(vars.$rSpacing, sm);

    .blending-row {
      display: flex;
      justify-content: space-between;
      gap: map.get(vars.$rSpacing, sm);

      .blending-cell {
        flex: 1;
        padding: map.get(vars.$rSpacing, sm);
        text-align: center;
        font-size: map.get(vars.$fontSizes, xl);
        background-color: map.get(vars.$colors, white);
        border: 3px solid map.get(vars.$colors, primaryDark);
        border-radius: map.get(vars.$radius, medium);
        box-shadow: map.get(vars.$shadows, medium);

        b {
          font-weight: bold;
          font-size: map.get(vars.$fontSizes, xl);
        }
      }
    }
  }
}
