@use "sass:map";
@use "../../vars" as vars;

.lesson-container {
  font-family: map.get(vars.$fonts, default);
  color: map.get(vars.$colors, textPrimary);
  padding: map.get(vars.$rSpacing, md);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .lesson-buttons {
    display: flex;
    justify-content: center;
    padding: map.get(vars.$rSpacing, md);

    button {
      background: map.get(vars.$colors, white);
      border: 1px solid map.get(vars.$colors, border);
      padding: 8px 12px;
      font-size: map.get(vars.$fontSizes, lg);
      margin: map.get(vars.$rSpacing, xs);
      cursor: pointer;

      &:hover {
        background: rgba(map.get(vars.$colors, charcoal), 0.25);
      }
    }
  }

  // Fullscreen overrides
  .lesson-buttons-fullscreen {
    width: 0;
    height: 0;

    button {
      pointer-events: all;
      background: rgba(map.get(vars.$colors, charcoal), 0.5);
      color: map.get(vars.$colors, white);
      border: none;
      margin: 0;

      &.prev-button {
        position: fixed;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }

      &.fullscreen-button {
        position: fixed;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
      }

      &.next-button {
        position: fixed;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }

      &:hover {
        background: rgba(map.get(vars.$colors, charcoal), 0.75);
      }
    }
  }

  &.lesson-fullscreen {
    height: 0;
  }

  .lb-none {
    display: none;
  }
}
