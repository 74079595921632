@use "sass:map";
@use "../../vars" as vars;

#contact-page {
  .contact-page-container {
    padding: map.get(vars.$rSpacing, md);
    align-content: center;

    > h2 {
      text-align: center;
      font-weight: map.get(vars.$fontWeights, bold);
    }

    .header {
      background-color: map.get(vars.$colors, primaryDark);
      padding: map.get(vars.$rSpacing, md);
      text-align: center;
      margin-bottom: map.get(vars.$rSpacing, lg);

      h1 {
        font-size: map.get(vars.$fontSizes, lg);
      }
    }

    .contact-info {
      display: flex;
      flex-direction: column;
      text-align: center;
      max-width: 1000px;
      margin: 0 auto;

      .col-1,
      .col-2 {
        flex: 1;
      }

      span {
        display: flex;
        justify-content: center;

        p {
          margin-left: map.get(vars.$rSpacing, sm);
        }
      }

      h2 {
        font-size: map.get(vars.$fontSizes, sm);
        margin: map.get(vars.$rSpacing, sm) 0;
      }

      .photo-of-me-container {
        margin: map.get(vars.$rSpacing, sm) 0;

        .photo-of-me {
          max-width: 25cqmin;
          height: auto;
          border-radius: 50%;
        }
      }

      .no-top-padding {
        margin-top: 0;
      }
    }

    .social-icons {
      display: flex;
      justify-content: center;
      gap: map.get(vars.$rSpacing, sm);
      margin-top: map.get(vars.$rSpacing, lg);

      a {
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}