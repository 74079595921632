@use "sass:map";
@use "../../../../../vars" as vars;

.guided-phonics-target-container {
  width: clamp(500px, 50cqmax, 75cqmin);
  height: calc(clamp(500px, 50cqmax, 75cqmin) * 9 / 16);
  background-color: map.get(vars.$colors, primary);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-size: cover;
  background-position: center;

  .left-panel,
  .right-panel {
    display: flex;
    flex-direction: column;
    background-color: rgba(map.get(vars.$colors, primary), 0.85);
    border: 4.25px solid map.get(vars.$colors, primaryDark);
    height: 80%;
    top: 10%;
    padding: map.get(vars.$rSpacing, sm);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .left-panel {
    width: 30%;
  }

  .right-panel {
    width: 50%;
  }

  .header {
    font-size: 2vh;
    color: map.get(vars.$colors, white);
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
    border-bottom: 4.25px solid map.get(vars.$colors, primaryDark);
    padding-bottom: 1vh;

    &.h-fullscreen {
      font-size: map.get(vars.$fontSizes, xxl);
    }
  }

  .li-body,
  .sc-body {
    flex-grow: 1;
    font-size: map.get(vars.$fontSizes, md);
    line-height: 1.5;

    > p {
      margin-left: map.get(vars.$rSpacing, lg);

      &:first-child {
        margin-left: 0;
      }
    }

    &.t-fullscreen {
      font-size: map.get(vars.$fontSizes, xl);
    }
  }

  .gray {
    color: map.get(vars.$colors, slate);
    font-style: italic;
  }

  .bold {
    font-weight: bold;
  }

  .half {
    display: inline-block;
    width: 50%;
  }

  .sc-pictures {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .sc-card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sc-image {
    height: 10vh;
    object-fit: contain;
  }

  .sc-graphemes {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }

  .grapheme-box {
    padding: 0.25rem 0.5rem;
    font-weight: bold;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-child {
      border: 4px solid map.get(vars.$colors, errorRed);
    }
  }
}
