@use "sass:map";
@use "../../../../../vars" as vars;

.learn-grapheme-slide-container {
  width: clamp(500px, 50cqmax, 75cqmin);
  height: calc(clamp(500px, 50cqmax, 75cqmin) * 9 / 16);
  background-color: map.get(vars.$colors, primary);
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;

  .learn-grapheme-overlay {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .grapheme-side,
  .photo-side {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 50%;
  }

  .grapheme-box {
    font-size: map.get(vars.$fontSizes, mxl);
    font-weight: bold;
    background-color:  map.get(vars.$colors, snow);
    border: 4px solid  map.get(vars.$colors, primaryDark);
    padding: map.get(vars.$rSpacing, sm) map.get(vars.$rSpacing, md);
    border-radius: map.get(vars.$radius, large);
    box-shadow: map.get(vars.$shadows, large);

    &.fullscreen {
      font-size: 8rem;
    }
  }

  .grapheme-photo {
    max-height: 80%;
    max-width: 80%;
    object-fit: contain;
    border-radius: 1rem;
    border: 3px solid  map.get(vars.$colors, primaryDark);
    background-color: white;
  }
}
