@use "sass:map";
@use "../../vars" as vars;

.profile {
  font-family: map.get(vars.$fonts, default);
  background: linear-gradient(
    to bottom,
    map.get(vars.$colors, skyBlue),
    map.get(vars.$colors, lavender)
  );
  color: map.get(vars.$colors, textPrimary);
  flex-grow: 1;
  padding: map.get(vars.$rSpacing, md);

  h1 {
    text-align: center;
    font-size: map.get(vars.$fontSizes, xxxl);
    color: map.get(vars.$colors, primary);
    text-shadow: map.get(vars.$shadows, small);
    margin-bottom: map.get(vars.$rSpacing, md);
  }

  p {
    text-align: center;
    font-size: map.get(vars.$fontSizes, xxl);
    color: map.get(vars.$colors, textprimaryDark);
    margin-bottom: map.get(vars.$rSpacing, lg);
  }

  .under-construction {
    text-align: center;
    font-style: italic;
    color: map.get(vars.$colors, error);
    font-size: map.get(vars.$fontSizes, xxl);
    margin-top: map.get(vars.$rSpacing, md);
  }
}
