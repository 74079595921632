@use "sass:map";
@use "../../../../../vars" as vars;

.visual-review-overview-container {
  width: clamp(500px, 50cqmax, 75cqmin);
  height: calc(clamp(500px, 50cqmax, 75cqmin) * 9 / 16);
  background-color: map.get(vars.$colors, primary);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center;

  .overview-title {
    text-align: center;
    font-size: map.get(vars.$fontSizes, xxl);
    color: map.get(vars.$colors, white);
    font-weight: bold;
    padding: map.get(vars.$rSpacing, sm);
  }

  .grapheme-item {
    padding: map.get(vars.$rSpacing, lg);
    border: 4px solid map.get(vars.$colors, primaryDark);
    box-shadow: map.get(vars.$shadows, large);
    border-radius: map.get(vars.$radius, large);
    background-color: map.get(vars.$colors, white);
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 60%;
    margin: 0 auto;
  }

  .grapheme-main {
    font-size: map.get(vars.$fontSizes, mxl);
    font-weight: bold;
    color: map.get(vars.$colors, charcoal);
    margin-bottom: map.get(vars.$rSpacing, md);
  }

  .bounty {
    position: absolute;
    top: 5%;
    left: 5%;
    font-size: map.get(vars.$fontSizes, xs);
    font-weight: bold;
  }

  .grapheme-buttons {
    display: flex;
    gap: map.get(vars.$rSpacing, sm);

    button {
      font-size: map.get(vars.$fontSizes, sm);
      padding: map.get(vars.$rSpacing, xs) map.get(vars.$rSpacing, sm);
      border-radius: map.get(vars.$radius, medium);
      border: none;
      cursor: pointer;
      background-color: map.get(vars.$colors, primary);
      color: map.get(vars.$colors, white);
      transition: background 0.2s;

      &:hover {
        background-color: map.get(vars.$colors, primaryDark);
      }
    }
  }

  &.slide-fullscreen {
    > .grapheme-item {
      top: 20cqh;
    }
  }
}
