@use "sass:map";
@use "../../vars" as vars;

.about {
  padding: map.get(vars.$rSpacing, md);

  .about-header {
    background-color: map.get(vars.$colors, primaryDark);
    padding: map.get(vars.$rSpacing, xl);
    text-align: center;

    h1 {
      font-size: map.get(vars.$fontSizes, xxLarge);
    }
  }

  .about-main {
    .about-page-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .left-col {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: map.get(vars.$rSpacing, md);
        margin-top: map.get(vars.$rSpacing, md);

        img {
          max-width: 100%;
          height: auto;
          margin-bottom: map.get(vars.$rSpacing, md);
        }

        .iframe-wrapper {
          display: relative;
          width: 100%;
          padding-bottom: 56.25%;
          margin-bottom: map.get(vars.$rSpacing, md);

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }

        h1,
        h2 {
          margin-bottom: map.get(vars.$rSpacing, md);
          font-size: map.get(vars.$fontSizes, lg);
        }

        p {
          margin-bottom: map.get(vars.$rSpacing, md);
        }
      }

      .right-container {
        max-width: 800px;
        margin-top: map.get(vars.$rSpacing, md);

        h2 {
          font-size: map.get(vars.$fontSizes, lg);
          margin-bottom: map.get(vars.$rSpacing, sm);
        }

        h2,
        h3 {
          font-size: map.get(vars.$fontSizes, lg);
          margin-bottom: map.get(vars.$rSpacing, sm);

          a {
            text-decoration: none;
            color: map.get(vars.$colors, primary);
          }
        }

        p {
          font-size: map.get(vars.$fontSizes, xl);
          margin-bottom: map.get(vars.$rSpacing, md);
        }
      }
    }
  }
}

@media (min-width: map.get(vars.$breakpoints, tablet)) {
  .about {
    .about-main {
      .about-page-content {
        flex-direction: row;
        justify-content: space-between;

        .left-col {
          flex: 1;
          max-width: 33%;
        }

        .right-container {
          flex: 2;
          padding-left: map.get(vars.$rSpacing, lg);
          max-width: 66%;
        }
      }
    }
  }
}

@media (min-width: map.get(vars.$breakpoints, laptop)) {
  .about {
    .about-header {
      h1 {
        font-size: map.get(vars.$fontSizes, xxxl);
      }
    }

    .about-main {
      .about-page-content {
        .right-container {
          h2 {
            font-size: map.get(vars.$fontSizes, xl);
          }

          p {
            font-size: map.get(vars.$fontSizes, lg);
          }
        }
      }
    }
  }
}
