@use "sass:map";
@use "../../vars" as vars;

.consonant-sounds-wrapper {
  position: absolute;
  z-index: 1;

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &.half-height {
    height: 50%;
  }

  &.half-width {
    width: 50%;
  }

  .consonant-sounds-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 1fr);
    width: 100%;
    height: 100%;
    background-color: map.get(vars.$colors, snow);

    .cell {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      box-sizing: border-box;
      overflow: hidden;
      border: 0.5px solid black;

      &.empty {
        background-color: map.get(vars.$colors, frost);
      }
      &.no-border {
        border: none;
      }
    }

    .cell.header {
      > div {
        color: map.get(vars.$colors, snow);
      }
      background-color: map.get(vars.$colors, charcoal);
      border-radius: map-get(vars.$radius, medium);
    }

    .highlight {
      background-color: map.get(vars.$colors, consonant);
    }

    .cell.image {
      position: relative;
      overflow: visible;
    }

    .image img {
      position: absolute;
      top: -20%;
      left: -20%;
      width: 140%;
      height: 140%;
      object-fit: contain;
      z-index: 1;

      &.not-face {
        z-index: 2;
      }
    }

    .merge-6x1 {
      grid-column: span 6;
      grid-row: span 1;
    }

    .text {
      font-weight: bold;
      z-index: 1;
      
      & > div > img.type-photo {
        width: 25%;
      }
    }
  }
}
